<template>
    <div :class="{'highlighted': hovering}"
         @mouseover="hovering = true"
         @mouseleave="hovering = false">
        <div class="d-flex flex-row ml-4 mr-4">
            <div class="p-2 d-flex flex-column flex-fill justify-content-top">
                <div class="d-flex flex-row ">
                    <small class="d-flex">{{ comment.created_by }}</small>
                    <small class="ml-1 mr-1">|</small>
                    <small class="d-flex">{{ formatDate(comment.updated_at) }}</small>
                </div>
                <div>
                    <div v-if="editing">
                        <textarea class="form-control" v-model="updatedText"
                                  v-on:keyup.enter="updateComment()"></textarea>
                    </div>
                    <div v-else>
                        {{ updatedText }}
                    </div>
                </div>
            </div>
            <div class="dropdown" v-if="hasPermissions('modify:items')">
                <transition name="fade">
                    <div class="ml-auto p-2" v-if="hovering && userIsAuthor" data-toggle="dropdown">
                        <i class="fas fa-1x fa-ellipsis-h" style="cursor: pointer;"></i>
                    </div>
                </transition>
                <div class="dropdown-menu dropdown-menu-right">
                    <span class="dropdown-item"
                          style="cursor: pointer"
                          @click="editing = !editing">Edit comment</span>
                    <span class="dropdown-item"
                          style="cursor: pointer"
                          @click="deleteComment()">Delete comment</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import {deleteComment, updateComment} from "../../services/CommentService";
    import { hasPermissions } from '../../helpers/permissionUtils';

    export default {
        name: 'CommentField',
        props: {
            comment: Object,
            item: Object
        },
        data() {
            return {
                username: localStorage.getItem('username'),
                hovering: false,
                editing: false,
                updatedText: this.comment.text
            }
        },
        methods: {
            formatDate(date) {
                return moment(date).format('ll');
            },
            async updateComment() {
                try {
                    this.editing = false;
                    await updateComment(this.comment.id, {text: this.updatedText});
                } catch (error) {
                    console.log(error);
                }
            },
            async deleteComment() {
                try {
                    await deleteComment(this.comment.id);
                    this.$emit('refreshComments')
                } catch (error) {
                    console.log(error);
                }
            },
            userIsAuthor() {
                return this.comment.user_id === localStorage.getItem('user_id');
            },
            hasPermissions,
        }
    };
</script>

<style scoped>
    .fade-enter-active {
        transition: opacity .2s;
    }

    .highlighted {
        background-color: #f5f5f5
    }
</style>