import axios from 'axios';

export const addComment = data => {
    return axios.post(`comments/`, data);
}

export const updateComment = (id, data) => {
    return axios.patch(`comments/${id}/`, data);
}

export const deleteComment = id => {
    return axios.delete(`comments/${id}/`)
}