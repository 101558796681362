<template>
    <div class="container my-4">
        <div class="card" v-if="item && product">
            <div class="card-header d-flex align-items-center">
                <strong>{{ product.name }}</strong>
                <span class="ml-2">#{{ item.id }}</span>
            </div>
            <div class="card-body row">
                <div class="options col-6">
                    <div class="d-flex flex-column">
                        <strong>Product Name</strong>
                        <span>{{ product.name }}</span>
                    </div>
                    <div class="d-flex flex-column mt-2">
                        <strong>Item ID</strong>
                        <span>{{ item.id }}</span>
                    </div>
                    <div class="d-flex flex-column mt-2">
                        <strong>Import Datetime</strong>
                        <span>{{ formatDatetime(item.created_at) }}</span>
                    </div>
                    <div class="d-flex flex-column mt-2">
                        <strong>Price</strong>
                        <span>{{ moneyFormat(item.cost) }}</span>
                    </div>
                    <div class="d-flex flex-column mt-2">
                        <strong>UUID</strong>
                        <span style="text-decoration: underline; cursor: pointer;"
                              @click="copyUUID()">{{ item.uuid }}</span>
                    </div>
                    <div class="d-flex flex-column mt-2">
                        <strong>Stock status</strong>
                        <span
                            @click="hasPermissions('modify:items') ? toggleInStock() : null"
                            :style="{ cursor: hasPermissions('modify:items') ? 'pointer' : 'inherit'}">
                            <ToggleButton
                                    style="pointer-events: none;"
                                    :value="item.in_stock"
                                    :labels="{checked: 'In', unchecked: 'Out'}"
                                    :width="60"
                                    :sync="true"
                                    :font-size="15"/>
                        </span>
                    </div>
                </div>
                <div class="comments col-6" v-if="hasPermissions('read:items')">
                    <ul class="list-group">
                        <li class="list-group-item comment-box px-0">
                            <div v-if="item.comments.length">
                                <ProductItemComment v-for="comment in comments"
                                                    :item="item"
                                                    :comment="comment"
                                                    @refreshComments="refreshItem()"></ProductItemComment>
                            </div>
                            <div v-else>
                                <span class="ml-4 mr-4">There are currently no comments to display</span>
                            </div>
                        </li>
                    </ul>
                    <textarea class="form-control form-item"
                              v-if="hasPermissions('modify:items')"
                              v-model="newComment.text"
                              v-on:keyup.enter="addComment()"
                              placeholder="New comment"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from "vue-simple-spinner";
    import {getProduct} from "../../services/ProductService";
    import {getItem, updateItem} from "../../services/ItemService";
    import moment from "moment";
    import dataTableMixin from "../../mixins/dataTable";
    import ProductItemComment from "../../components/forms/ProductItemComment";
    import {addComment} from "../../services/CommentService";
    import {ToggleButton} from 'vue-js-toggle-button';
    import moneyFormat from "../../mixins/moneyFormat";
    import { hasPermissions } from '../../helpers/permissionUtils'

    export default {
        name: 'ProductItemDetails',
        components: {
            Spinner,
            ProductItemComment,
            ToggleButton
        },
        mixins: [
            dataTableMixin,
            moneyFormat
        ],
        data() {
            return {
                loading: false,
                product: null,
                item: null,
                comments: null,
                username: localStorage.getItem('username'),
                currentDate: moment().toISOString(),
                currentDateFormatted: moment().format('ll'),
                newComment: {
                    user: localStorage.getItem('user_id'),
                    item: null,
                    text: ''
                }
            };
        },
        async mounted() {
            try {
                this.loading = true;
                await this.getProductItem();
                this.currentDate = moment().toISOString();
                if (!this.item.comments.length) return;
                this.createDataTable('#comments-table');
            } catch (error) {
                console.log(error);
            }
            this.loading = false;

            // Scroll comment box to the bottom on load
            let commentBox = document.getElementsByClassName("comment-box")[0];
            commentBox.scrollTop = commentBox.scrollHeight;
        },
        methods: {
            async getProductItem() {
                try {
                    let productResponse = await getProduct(this.$route.params.product_id);
                    this.product = productResponse.data;
                    let itemResponse = await getItem(this.$route.params.item_id);
                    this.item = itemResponse.data;
                    this.comments = this.item.comments;
                } catch (error) {
                    console.log(error);
                }
            },
            async addComment() {
                try {
                    this.newComment.item = this.item.id;
                    await addComment(this.newComment);
                } catch (error) {
                    console.log(error);
                }
                this.newComment.text = '';
                await this.getProductItem();
                // Scroll comment box to the bottom
                let commentBox = document.getElementsByClassName("comment-box")[0];
                commentBox.scrollTop = commentBox.scrollHeight;
            },
            formatDate(date) {
                return moment(date).format('ll');
            },
            formatDatetime(date) {
                return moment(date).format('LLL');
            },
            copyUUID() {
                const el = document.createElement('textarea');
                el.value = this.item.uuid;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            },
            async toggleInStock() {
                try {
                    await updateItem(this.item.id, {in_stock: !this.item.in_stock});
                    this.item.in_stock = !this.item.in_stock;
                } catch (error) {
                    console.log(error);
                }
            },
            async refreshItem() {
                await this.getProductItem();
            },
            hasPermissions,
        }
    };
</script>

<style scoped>
    .comment-box {
        max-height: 500px;
        overflow-y: scroll;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .form-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
</style>